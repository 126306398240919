<template>
    <div>
        <div class="card-tail">
            <TopBarClients @searchInputChange="searchInputChange" @searchParametersChanged="syncDataWithQueryParameters" />
        </div>
        <div class="card-tail" v-loading="$waiting.is('loading')">
            <el-table :default-sort="preSelectedSort" :data="localclientsFactoringList" size="medium" @current-change="handleCurrentChange">
                <el-table-column prop="clientName" label="Klient" sortable="custom" />
                <el-table-column prop="hasActiveOpenBanking" label="OpenBanking" align="right" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.hasActiveOpenBanking == true" style="color: green">
                            <i class="fa-solid fa-check fa-xl" title="Har aktiv openbanking" />
                        </span>
                        <span v-else style="color: red">
                            <i class="fa-solid fa-triangle-exclamation fa-xl" title="Saknar aktiv openbanking" />
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="decisionStatusText" label="Status" width="120" />
                <el-table-column prop="openErrands" label="Öppna ärenden" align="right" width="140" />
                <el-table-column prop="usedCred" label="Nyttjad kredit" align="right" width="180">
                    <template slot-scope="scope">{{ scope.row.usedCred | swedishNumberFormat }} SEK</template>
                </el-table-column>
                <el-table-column prop="overdueCred" label="Förfallen kredit" align="right" width="180">
                    <template slot-scope="scope">{{ scope.row.overdueCred | swedishNumberFormat }} SEK</template>
                </el-table-column>
                <el-table-column prop="approvedCred" label="Godkänd kredit" sortable="custom" align="right" width="180">
                    <template slot-scope="scope">{{ scope.row.approvedCred | swedishNumberFormat }} SEK</template>
                </el-table-column>
                <el-table-column prop="automaticCred" label="Automatisk kredit" sortable="custom" align="right" width="180">
                    <template slot-scope="scope">{{ scope.row.automaticCred | swedishNumberFormat }} SEK</template>
                </el-table-column>
            </el-table>
            <p style="margin-top:10px;">Total nyttjad kredit: {{ totalUsedCred | swedishNumberFormat }} SEK</p>
            <p>Total förfallen kredit: {{ totalOverdueCred | swedishNumberFormat }} SEK</p>
        </div>
        <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalClientNumber" />
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../factoring.api.js";
export default {
    props: {
        clientsFactoringList: {
            type: Array,
            default: () => [],
        },
        clientStatus: {
            type: String,
            default: "Active",
        },
    },

    data() {
        return {
            totalClientNumber: 0,
            localclientsFactoringList: [],
            totalUsedCred: 0,
            totalOverdueCred: 0,
        };
    },

    components: {
        TopBarClients: () => import(/* webpackChunkName: "FactoringTopBar" */ "./TopBarClients.vue"),
        Pagination: () => import(/* webpackChunkName: "PaginationEl" */ "../../../components/paginationEl.vue"),
    },

    created() {
        this.updateUrlAndGetAll();
    },

    methods: {
        handleCurrentChange(val) {
            console.log("🚀 ~ handleCurrentChange ~ val.decisionStatusText:", val.decisionStatusText);
            if (val.decisionStatusText === null) {
                if (window.confirm(`Vill du skapa ett kreditbeslut för ${val.clientName}?`)) {
                    this.$router.push({ path: `/clientApproved/${val.clientId}/credit` });
                }
            } else {
                this.$router.push({ path: `/clientApproved/${val.clientId}/credit` });
            }
        },

        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        sortChange(event) {
            console.log("SORTCHANGE");
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            query.clientStatus = this.clientStatus;
            query.isTest = this.isTest;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
            //this.syncDataWithQueryParameters();
        },

        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },

        syncDataWithQueryParameters() {
            setTimeout(() => {
                //this.$refs.paginationComponent.updateUrl(+this.pageNumber);
                this.updateUrlAndGetAll();
            }, 200);
        },

        updateUrlAndGetAll() {
            const { pageNumber, pageSize, sortBy, sortByType, search, status } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            //this.status = status && status !== "totalClients" ? status : "";
            this.status = status && status !== "approvedCount" ? status : "approvedCount";
            this.getClientsFactoringList();
        },

        async getClientsFactoringList() {
            this.$waiting.start("loading");
            var response = await Api.getClientsFactoringList(this.pageNumber, this.pageSize, this.query, this.sortBy, this.sortByType, this.clientName, this.clientNr, this.status);
            this.localclientsFactoringList = [...response.data.clients];
            this.totalUsedCred = response.data.totalUsedCred;
            this.totalOverdueCred = response.data.totalOverdueCred;
            this.totalClientNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },
};
</script>
